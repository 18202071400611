import { isBrowser } from '.';
import { FootprintsEvents } from './footprintsEvent';

declare global {
  interface Window {
    context: any;
  }
}

let mixpanel = null;
const FootprintsApiUrl = 'https://footprints.instamojo.com/';
interface MixpanelOptions {
  send_immediately?: boolean;
  transport?: 'xhr' | 'sendBeacon';
}

const FootprintsAPI = {
  /**
   * Generates browser related data to be sent to analytics service's
   * http endpoint (yet to be implemented). This data replicates
   * mixpanel's special data which is genrated by mixpanel-js library
   * internally and sent to mixpanel when we call mixpanel.track(). In
   * order to send the same data to analytics service, we need to
   * generate it manually.
   */

  sendEvent: function (eventName, eventData, callback) {
    const payload = {
      event_name: eventName,
      event_source: 'smartpage-webapp',
      event_time: Math.floor(Date.now() / 1000),
      event_data: eventData,
    };

    fetch(FootprintsApiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
      })
      .catch((error) => console.log('Failed Footprints Event',error));

    if (callback instanceof Function) {
      callback();
    }
  },
};

export const trackEvent = (
  eventName: string,
  eventProperties: Record<string, unknown> | undefined,
  options?: MixpanelOptions,
  callback?: () => void,
): void => {
  if (!isBrowser()) {
    return;
  }
  try {
    if (FootprintsEvents.includes(eventName)) {
      FootprintsAPI.sendEvent(eventName, eventProperties, callback);
    } else {
      if (!mixpanel) {
        import('mixpanel-browser').then((Mixpanel) => {
          mixpanel = Mixpanel;
          mixpanel?.default?.init(window?.context?.MIXPANEL_TOKEN);
          console.log('mixpanel initialised');
          mixpanel?.default?.track(eventName, eventProperties, options, callback);
        });
      } else {
        console.log('mixpanel tracking');
        mixpanel?.default?.track(eventName, eventProperties, options, callback);
      }
    }
  } catch (e) {
    console.error('[Failed] Analytics:', e);
  }
};
